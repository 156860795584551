<template>
<div class="home-page">
    <div class="feature-group">
        <FeatureView />
    </div>
    <AboutView :title="content?.aboutTitle" :body="content?.aboutBody" :featured="content?.featured" />
    <BlogView :title="content?.blogTitle" />
    <PacksView :title="content?.packsTitle" :body="content?.packsBody" />
    <SocialView :title="content?.socialTitle" :body="content?.socialBody" />
    <FAQView :entries="content?.faqEntries" :title="content?.faqTitle" :body="content?.faqBody" />
    <SaleBanner v-if="saleBannerLocaleAvailable && !saleBannerTimedOut" v-on:close="showSaleBanner = false" />
</div>
</template>

<script lang="ts">
import FeatureView from '$components/homepage/Feature.vue'
import AboutView from '$components/homepage/About.vue'
import BlogView from '$components/homepage/Blog.vue'
import PacksView from '$components/homepage/Packs.vue'
import SocialView from '$components/homepage/Social.vue'
import FAQView from '$components/homepage/FAQ.vue'
import SaleBanner from '$components/shopping/SaleBanner.vue'
// import { I18n } from '$services/i18n'
import type { JBGWeb } from '$types/JBGWeb'

export default defineNuxtComponent({
    components: {
        FeatureView,
        AboutView,
        BlogView,
        PacksView,
        SocialView,
        FAQView,
        SaleBanner
    },
    fetchKey() {
        const { $i18n } = useNuxtApp()
        return `home-${$i18n.locale.value}`
    },
    data() {
        return {
            content: <JBGWeb.Homepage|null> null,
            showSaleBanner: false, // set to true to enable sale banner
            isLoading: true,
            isError: false
        }
    },

    async asyncData({ $cms, $i18n }) {
        try {
            const homepageContent = await $cms.fetchOne<JBGWeb.Homepage>('home-page', null, {
                populate: ['faqEntries', 'featured', 'featured.tile'],
                locale: $i18n.locale.value
            })

            return {
                content: homepageContent,
                isLoading: false,
                isError: false
            }
        } catch (error) {
            console.error('Failed to fetch Homepage content:', error.message)
            return {
                isLoading: false,
                isError: true
            }
        }
    },

    head({ $i18n }) {
        return {
            title: `${$i18n.t('HOME.HEADER')}`
        }
    },

    computed: {
        saleBannerLocaleAvailable(): boolean {
            // uncomment when enabling banner
            // if (this.showSaleBanner) {
            //     switch (this.$i18n.locale) {
            //     case 'en':
            //     case 'de':
            //     case 'es':
            //     case 'es-XL':
            //     case 'es-419':
            //         return true
            //     default:
            //         return false
            //     }
            // }
            return false
        },

        saleBannerTimedOut() {
            // uncomment when enabling banner
            // const saleBannerTimeoutCookieExists = document.cookie.indexOf('sale_banner_timeout') !== -1
            // if (saleBannerTimeoutCookieExists) return true
            // return false
            return true // remove this line next time the banner is enabled
        }
    },

    mounted() {
        if (import.meta.client) {
            // only for desktop sizes
            if (window.innerWidth <= 600) {
                // check for cc_cookie. We don't want the sale banner to show if the cookie banner is present.
                const ccExists = document.cookie.indexOf('cc_cookie') !== -1
                if (!ccExists && !this.saleBannerTimedOut) this.showSaleBanner = false
            }

            window.addEventListener('cc:onFirstConsent', () => {
                const sbtc = document.cookie.indexOf('sale_banner_timeout') !== -1
                if (!sbtc) this.showSaleBanner = true
            })
        }
    },

    beforeUnmount() {
        if (import.meta.client) {
            window.removeEventListener('cc:onFirstConsent', () => {
                const sbtc = document.cookie.indexOf('sale_banner_timeout') !== -1
                if (!sbtc) this.showSaleBanner = true
            })
        }
    }
})
</script>

<style lang="scss">
@use "$styles/kit.scss" as *;

.home-page {
    background: url("/images/homepage/sparkle-bg.png");
    background-attachment: fixed;
}

.home-section {
    color: var(--neutral-50);
    h1 {
        margin-top: 0;
        font-weight: 800;
        font-size: 48px;
        text-transform: uppercase;
    }

    h2, h3 {
        margin-top: 0;
        font-weight: 700;
        font-size: 20px;
    }

    .section-small-header {
        font-size: 16px;
        font-style: italic;
        font-weight: 400;
        text-transform: none;
        margin-bottom: 5px;

        @include mq-xsmall {
            font-size: 12px;
            text-align: center;
        }
    }

    .section-title {
        margin-top: 0;
        font-weight: 800;
        font-size: 40px;
        text-transform: uppercase;
        margin-bottom: 24px;

        @include mq-medium {
            font-size: 36px;
        }

        @include mq-small {
            font-size: 32px;
        }

        @include mq-xsmall {
            font-size: 28px;
            text-align: center;
        }
    }

    .section-subtitle {
        margin-top: 0;
        font-weight: 600;
        font-size: 20px;
    }

    .section-wrapper {
        max-width: 1440px;
        margin: 0 auto;
        padding: 100px 80px;
    }
}

@include mq-medium {
    .home-section {
        .section-title {
            font-size: 32px;
        }

        .section-wrapper {
            padding: 80px 44px;
        }
    }
}

@include mq-small {
    .home-section {
        .section-title {
            font-size: 32px;
        }

        .section-wrapper {
            padding: 80px 16px;
        }
    }
}

@include mq-xsmall {
    .home-section {
        .section-title {
            font-size: 28px;
        }

        .section-wrapper {
            padding: 40px 15px;
        }
    }
}

.feature-group {
    display: flex;
    flex-direction: column;
    @include mq-xsmall {
        flex-direction: column-reverse;
    };
}

</style>
