<template>
<div class="carousel-item">
    <div class="feature">
        <div class="overlay">
            <NuxtLink :to="packDetailsPage">
                <img
                    src="/images/homepage/packs/jpp6-feature.png"
                    :alt="$t('HOME.TJPS_LOGO')"
                    class="logo"
                    tabindex="0"
                >
            </NuxtLink>
            <div class="flex-column cta">
                <AddToCartButton :product="product" :pack="pack" />
                <NuxtLink :to="packDetailsPage">
                    <button class="learn-more outline wide">
                        <span class="button-text">{{ $t('GAME_DETAIL.LEARN_MORE') }}</span>
                        <img
                            src="/images/icons/arrow-right.svg" alt="->" class="arrow-right"
                        >
                    </button>
                </NuxtLink>
                <StorefrontIcons
                    v-if="platformOptions && platformOptions.length > 0" :pack="pack"
                    :platform-options="platformOptions" :fell-back-to-en="true" :is-localized="false"
                />
            </div>
        </div>
        <video
            class="bg-video" poster="/images/homepage/tmp2_bg_poster.jpg" loop
            autoplay
            muted playsinline
        >
            <source src="/images/homepage/tmp2_bg_vid.webm" type="video/webm">
        </video>
    </div>
</div>
</template>

<script lang="ts">
import type { Shopify } from '@jackboxgames/shopify'
import StorefrontIcons from '$components/shopping/StorefrontIcons.vue'
import { getStorefrontsFromProductData } from '$services/storefronts/storefronts'
import AddToCartButton from '$components/shopping/AddToCartButton.vue'
import type { JBGWeb } from '$types/JBGWeb'
import { Geolocate } from '$services/geolocate'

export default defineNuxtComponent({
    components: {
        AddToCartButton,
        StorefrontIcons
    },

    data() {
        return {
            pack: <JBGWeb.PackDetail | null> null,
            product: <Shopify.Product | null> null
        }
    },

    async asyncData({ $cms, $i18n }) {
        const data = {}
        const countryCode = Geolocate.getCountryCode()
        try {
            data.pack = await $cms.fetchOne<JBGWeb.PackDetail>('pack-details', 'the-jackbox-party-pack-6', {
                populate: ['shopifyProduct'],
                locale: $i18n.locale.value,
                countryCode
            })
        } catch (error) {
            // TODO: remove this when we can load localized games
            if ((error as Error).message === 'No data') {
                try {
                    // force a fetch in en
                    const pack = await $cms.fetchOne<JBGWeb.PackDetail>('pack-details', 'the-jackbox-party-pack-6', {
                        populate: ['shopifyProduct'],
                        locale: 'en',
                        countryCode
                    })

                    data.pack = pack
                } catch (error) {
                    console.error('Failed to fetch pack details for TMP2:', error.message)
                }
            } else {
                console.error('Failed to fetch pack details for TMP2:', error.message)
            }
        }

        data.product = data.pack.shopifyProduct

        return data
    },

    computed: {
        platformOptions() {
            if (!this.product) return
            return getStorefrontsFromProductData(this.product)
        },

        packDetailsPage() {
            const slug = 'the-jackbox-party-pack-6'
            return this.localeRoute({
                name: 'games-packs-packslug',
                params: {
                    packslug: slug
                }
            })
        }
    }
})
</script>

<style lang="scss" scoped>
@use "$styles/kit.scss" as *;

$overlayGradient: linear-gradient(90deg,
    rgba(0,0,0,.8) 0%,
    rgba(0,0,0,0.6) 80%,
    rgba(0,0,0,0) 100%);

.feature {
    position: relative;
    height: 100%;
    .bg-video {
        top: 0;
        position: absolute;
        width: 100%;
        @include mq-xsmall {
            display: none;
        }
    }
    @include mq-xsmall {
        background-image: url('/images/homepage/pp6-carousel-slide-mobile-YES-gradient.jpg');
        background:
            linear-gradient(
                0deg, rgba(0, 0, 0, 0.64) 0%,
                rgba(0, 0, 0, 0.48) 26.95%,
                rgba(0, 0, 0, 0.19) 45.55%,
                rgba(0, 0, 0, 0.00) 100%
            ),
            image-set(url(/images/homepage/pp6-carousel-slide-mobile-YES-gradient.jpg));
        background-size: cover;
        background-repeat: no-repeat;
        justify-content: center;
        align-items: end;
        padding-bottom: 16px;
    }
    .overlay-container {
        position: relative;
        z-index: 10;
        height: 100%;
        border: 10px solid #00ffff;
    }
    .overlay {
        position: relative;
        height: 100%;
        z-index: 10;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 0 80px;
        max-width: 450px;
        background: $overlayGradient;
        @include mq-medium {
            max-width: 400px;
        }
        @include mq-small {
            padding: 0 20px;
            max-width: 350px;
        }
        @include mq-xsmall {
            background: none;
            justify-content: flex-end;
        }

        .logo {
            padding: 0 20px;
            width: 100%;
            max-width: 360px;
            height: auto;
            @include mq-medium {
                padding: 0 40px;
                max-width: 300px;
            }
            @include mq-small {
                padding: 0 40px;
                max-width: 240px;
            }
            @include mq-xsmall {
                height: 140px;
                width: auto;
            }
        }

        button {
            cursor: pointer;

            &.learn-more {
                font-size: 1em;
                text-align: left;
                display: flex;
                justify-content: space-between;
                align-items: center;

                &:hover {
                    @include mq-small-and-less {
                        padding: 11px;
                    }
                }

                .arrow-right {
                    height: 14px;
                }
                @include mq-small-and-less {
                    padding: 12px;
                }
            }
        }
    }
}

.logo {
    cursor: pointer;
    padding: 8px;

    &:hover {
        transition: transform .2s;
        transform: scale(1.05, 1.05);
    }
}

</style>
